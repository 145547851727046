<template>
  <b-card class="card-custom rounded-top-0" body-class="pt-15">
    <template v-slot:footer>
      <b-container class="max-w-600px text-right px-0">
        <b-btn variant="primary" v-cs-loading="submiting" class="px-5" @click="onSaveData()">
          Save
        </b-btn>
      </b-container>
    </template>
    <b-container class="max-w-600px px-0">
      <b-form-group>
        <template v-slot:label>
          Old Password
          <span class="text-danger">*</span>
        </template>
        <b-form-input type="password" class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5" v-model="form.current_password" :state="validateState($v.form.current_password)" />
      </b-form-group>
      <b-form-group>
        <template v-slot:label>
          New Password (min 8 characters)
          <span class="text-danger">*</span>
        </template>
        <b-form-input type="password" class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5" v-model="form.password" :state="validateState($v.form.password)" />
      </b-form-group>
      <b-form-group>
        <template v-slot:label>
          Confirm Password
          <span class="text-danger">*</span>
        </template>
        <b-form-input
          type="password"
          class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          v-model="form.password_confirmation"
          :state="validateState($v.form.password_confirmation)"
        />
      </b-form-group>
      <!--end::Form Group-->
    </b-container>
  </b-card>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
  name: 'profile-settings',
  validations: {
    form: {
      current_password: { required, minLength: minLength(8) },
      password: { required, minLength: minLength(8) },
      password_confirmation: { required, sameAs: sameAs('password') },
    },
  },
  data() {
    return {
      submiting: false,
      form: {
        current_password: '',
        password: '',
        password_confirmation: '',
      },
    };
  },
  computed: {
    userData() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    const name = [this.userData.first_name || '', this.userData.last_name || ''].filter((e) => e != '');
    this.$root.$emit('changeTitle', name.join(' ') || this.userData.email);
  },
  methods: {
    ...mapActions({
      passwordReset: 'auth/passwordReset',
      updateProfile: 'auth/updateProfile',
    }),
    onSaveData() {
      if (!this.$v.form.$invalid) {
        this.submiting = true;
        this.updateProfile({ admin_user: this.form }).finally(() => {
          this.form = {
            current_password: '',
            password: '',
            password_confirmation: '',
          };
          this.$v.form.$reset();
          this.submiting = false;
        });
      } else {
        this.$v.$touch();
      }
    },
  },
};
</script>
